import { getReprimand } from '@/services/api/reprimand.js';
import { reactive } from 'vue';

const reprimand = reactive({});

/**
 * @param {number} reprimandId
 * @returns {object}
 */
const fetchReprimand = async (reprimandId) => {
  const _reprimand = await getReprimand(reprimandId);
  Object.assign(reprimand, _reprimand);

  return _reprimand;
};

export { reprimand, fetchReprimand };
