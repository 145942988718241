import axios from '../../plugins/axios.js';

export const getDebts = (request, config = undefined) => {
  return axios
    .get('/api/v1/debts', { ...config, params: request })
    .then((response) => {
      return response.data;
    });
};

export const getDebt = (id, config = undefined) => {
  return axios.get(`/api/v1/debts/${id}`, config).then(({ data }) => {
    let debt = data.data;
    debt.reason = parseInt(debt.reason);

    return debt;
  });
};

export const getStatuses = (request, config = undefined) => {
  return axios
    .get(`/api/v1/debts/statuses`, { ...config, params: request })
    .then(({ data }) => data);
};

export const getLinks = (id, res_type, config = undefined) => {
  return axios
    .get(`/api/v1/debts/${id}/links/${res_type}`, config)
    .then(({ data }) => data.links);
};

export const sortLinks = (debt_link_id, direction, config = undefined) => {
  return axios
    .post(`/api/v1/debts/links/sort`, { debt_link_id, direction })
    .then(({ data }) => data.data);
};

export const saveNote = (id, note, config = undefined) => {
  return axios
    .post(`/api/v1/debts/${id}/notes`, note, config)
    .then(({ data }) => data);
};

export const createDebt = (debt, config = undefined) => {
  debugger;
  return axios
    .post(`/api/v1/debts`, debt, config)
    .then(({ data }) => data.data);
};
