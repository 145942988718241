<template>
  <v-row>
    <v-col cols="12" class="text-center font-weight-bold">
      Delivery information
    </v-col>

    <v-col cols="12">
      <v-autocomplete
        v-model="model.receive_type"
        :items="receiveTypes"
        :error-messages="errors?.receive_type"
        label="Receive type"
        clearable
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        v-model="model.received_by"
        label="Received by"
        :error-messages="errors?.received_by"
      />
    </v-col>
  </v-row>
</template>

<script setup>
import { stepProps } from '@/composables/task/stepOptions';
import receiveTypes from '@/data/receiveTypes.json';

defineProps({
  ...stepProps,
});

const model = defineModel({ required: true, type: Object });
</script>
