<template>
  <v-card :loading="loadingTask">
    <v-card-title> Task </v-card-title>

    <v-card-text>
      <v-row v-if="hasSection">
        <v-col cols="12">
          <v-skeleton-loader type="text" :loading="loadingTask">
            <span>{{ task.section.ref_num }}</span>
          </v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row v-if="hasSectionWithLinks">
        <v-col cols="6">
          <small class="d-block">Creditor(s)</small>
          <LinksList
            :section-id="task.section_id"
            :section="task.section"
            res-type="creditor"
          />
        </v-col>

        <v-col cols="6">
          <small class="d-block">Debtor(s)</small>
          <LinksList
            :section-id="task.section_id"
            :section="task.section"
            res-type="debtor"
          />
        </v-col>
      </v-row>

      <hr />

      <v-form id="task-info">
        <v-row>
          <v-col cols="6">
            <v-skeleton-loader type="text" :loading="loadingTask">
              <v-text-field
                v-model="task.due_date"
                type="date"
                label="Deadline"
                variant="filled"
              />
            </v-skeleton-loader>
          </v-col>

          <v-col cols="6">
            <v-skeleton-loader type="text" :loading="loadingTask">
              <v-autocomplete
                v-model="task.priority"
                :items="priorities"
                label="Priority"
                variant="filled"
              />
            </v-skeleton-loader>
          </v-col>

          <v-col cols="6">
            <v-skeleton-loader type="text" :loading="loadingTask">
              <v-autocomplete
                v-model="taskForModel"
                v-model:search="taskForSearch"
                :items="taskForItems"
                :loading="taskForLoading"
                :no-data-text="taskForNoDataText"
                label="Assignee"
                no-filter
                clearable
              />
            </v-skeleton-loader>
          </v-col>

          <v-col cols="6" class="text-right">
            <small class="d-block">Task given by</small>
            <UserLink v-if="task.taskBy" :user="task.taskBy" bold />
          </v-col>
        </v-row>

        <v-row v-if="isStep">
          <v-col cols="6">
            <v-skeleton-loader type="text" :loading="loadingTask">
              <v-text-field
                v-model="task.action_date"
                label="Step Date"
                variant="filled"
                type="date"
              />
            </v-skeleton-loader>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="task.name"
              label="Task Name"
              variant="filled"
              clearable
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="task.description"
              label="Task Description"
              variant="filled"
              clearable
            />
          </v-col>
        </v-row>
      </v-form>

      <v-row>
        <v-col cols="6">
          <v-skeleton-loader type="text" :loading="loadingTask">
            <small> Creation Date: {{ task.created_at }} </small>
          </v-skeleton-loader>
        </v-col>

        <v-col cols="6" class="text-right">
          <v-skeleton-loader
            v-if="loadingTask || task.completedBy"
            type="text"
            :loading="loadingTask"
          >
            <small>
              Completed: {{ task.completed_at }} by
              <UserLink :user="task.completedBy" bold />
            </small>
          </v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-btn v-if="hasChangeTaskInfo" block color="green" type="submit">
            Update
          </v-btn>
        </v-col>
      </v-row>

      <hr />

      <v-row>
        <v-col cols="12">
          <v-textarea
            v-model="commentInput"
            label="Comment"
            variant="filled"
            flat
          />
        </v-col>
      </v-row>

      <v-row>
        <div v-for="comment in comments" :key="comment.id">
          <v-col cols="6">
            <PeopleLink :person="comment.person" />
          </v-col>

          <v-col cols="6">
            <small>{{ comment.created_at }}</small>
          </v-col>

          <v-col cols="12">
            {{ comment.comment }}
          </v-col>
        </div>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import LinksList from '@/components/general/LinksList.vue';
import PeopleLink from '@/components/links/PeopleLink.vue';
import UserLink from '@/components/links/UserLink.vue';
import { useEmployeeAutocomplete } from '@/composables/autocomplete/employee-autocomplete';
import { getTask } from '@/services/api/task.js';

export default {
  components: {
    LinksList,
    PeopleLink,
    UserLink,
  },
  props: {
    taskId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    let taskFor = useEmployeeAutocomplete({ minSearchLength: 1 });
    taskFor = {
      taskForModel: taskFor.employeeModel,
      taskForSearch: taskFor.employeeSearch,
      taskForItems: taskFor.employees,
      taskForLoading: taskFor.loadingEmployees,
      taskForNoDataText: taskFor.noEmployeeDataText,
      setPreselectedEmployee: taskFor.setPreselectedEmployee,
    };

    return {
      ...taskFor,
    };
  },
  data: () => ({
    task: {
      section: {},
    },
    originalTask: {},
    priorities: [
      { title: 'None', value: 0 },
      { title: 'Low', value: 1 },
      { title: 'Medium-low', value: 2 },
      { title: 'Medium', value: 3 },
      { title: 'Medium-high', value: 4 },
      { title: 'High', value: 5 },
    ],
    comments: [],
    commentInput: '',
    loadingTask: false,
    loadingComments: false,
  }),
  computed: {
    hasChangeTaskInfo() {
      if (this.task.name !== this.originalTask.name) {
        return true;
      }

      if (this.task.description !== this.originalTask.description) {
        return true;
      }

      return false;
    },
    isStep() {
      if (this.hasSectionWithLinks) {
        return true;
      }

      return ['impound'].includes(this.task.section);
    },
    hasSectionWithLinks() {
      return ['debt', 'storage', 'reprimand'].includes(this.task.section_id);
    },
    hasSection() {
      return this.task?.section?.id;
    },
  },
  created() {
    this.fetchTask();
    this.fetchComments();
  },
  methods: {
    fetchTask() {
      this.loadingTask = true;
      this.task = {};
      this.originalTask = {};

      getTask(this.taskId)
        .then((task) => {
          this.task = { ...task };
          this.originalTask = { ...task };
          this.setPreselectedEmployee(task.task_for_id);
        })
        .finally(() => {
          this.loadingTask = false;
        });
    },
    fetchComments() {
      this.loadingComments = true;
      console.log('fetchComments', this.taskId);
    },
  },
};
</script>
