<template>
  <VProgressCircular v-if="loading" indeterminate />

  <div v-else-if="!links.length">
    Geen {{ resType == 'creditor' ? 'Crediteur' : 'Debiteur' }}
  </div>

  <div
    v-for="(link, index) in links"
    v-else
    :key="link.id"
    class="d-flex justify-content-between py-1"
  >
    <template v-if="link.department">
      <DepartmentLink :department="link.department" bold dep />
    </template>

    <template v-else>
      <PeopleLink :person="link.person" bold middle-name />
    </template>

    <div v-if="!noButtons" class="d-print-none">
      <template
        v-if="links.length > 1 && $gates.hasPermission(section + '.update')"
      >
        <v-btn
          v-if="links.length > 1 && index"
          icon="mdi-arrow-up"
          color="blue"
          size="small"
          variant="text"
          @click="moveItem(link, 'up')"
        />

        <v-btn
          v-if="links.length > 1 && index < links.length - 1"
          icon="mdi-arrow-down"
          color="blue"
          size="small"
          variant="text"
          @click="moveItem(link, 'down')"
        />
      </template>

      <DeleteButton
        v-if="$gates.hasPermission(section + '.delete')"
        :url="removeUrl(link)"
        @deleted="linkDeleted(index)"
      >
        <template #default="{ props }">
          <v-btn
            v-can="section + '.delete'"
            v-bind="props"
            icon="mdi-trash-can"
            size="small"
            color="red"
            variant="text"
          />
        </template>
      </DeleteButton>
    </div>
  </div>
</template>

<script setup>
import PeopleLink from '@/components/links/PeopleLink.vue';
import DepartmentLink from '@/components/links/DepartmentLink.vue';
import DeleteButton from '@/components/general/DeleteButton.vue';

import {
  getLinks as getDebtLinks,
  sortLinks as sortDebtLinks,
} from '@/services/api/debt.js';
import {
  getLinks as getRepossessionLinks,
  sortLinks as sortRepossessionLinks,
} from '@/services/api/repossession.js';
import {
  getLinks as getReprimandLinks,
  sortLinks as sortReprimandLinks,
} from '@/services/api/reprimand.js';
import { ref } from 'vue';

const { resType, section, sectionId } = defineProps({
  resType: {
    type: String,
    required: true,
    default: null,
  },

  section: {
    type: String,
    required: true,
    default: null,
  },

  sectionId: {
    type: [Number, String],
    required: true,
    default: null,
  },

  noButtons: {
    type: Boolean,
    default: false,
  },
});

const links = ref([]);
const loading = ref(true);

fetchLinks();

/**
 * @param {object} link
 * @returns {string}
 */
function removeUrl(link) {
  return route('api.v1.' + section + 's.links.destroy', link.id);
}

function fetchLinks() {
  if (!Number.isInteger(parseInt(sectionId))) {
    console.warn(`Link id is not a number (${sectionId})`);
    return;
  }

  loading.value = true;

  if (section == 'debt') {
    getDebtLinks(sectionId, resType).then((_links) => {
      links.value = _links;
      loading.value = false;
    });
  } else if (section == 'repossession') {
    getRepossessionLinks(sectionId, resType).then((_links) => {
      links.value = _links;
      loading.value = false;
    });
  } else if (section == 'reprimand') {
    getReprimandLinks(sectionId, resType).then((_links) => {
      links.value = _links;
      loading.value = false;
    });
  }
}

/**
 * @param {object} link
 * @param {('down'|'up')} direction
 */
function moveItem(link, direction) {
  loading.value = true;

  console.log(link);

  if (section == 'debt') {
    sortDebtLinks(link.id, direction).then(() => {
      fetchLinks();
    });
  } else if (section == 'repossession') {
    sortRepossessionLinks(link.id, direction).then(() => {
      fetchLinks();
    });
  } else if (section == 'reprimand') {
    sortReprimandLinks(link.id, direction).then(() => {
      fetchLinks();
    });
  }
}

/**
 *
 * @param {number} index
 */
function linkDeleted(index) {
  links.value.splice(index, 1);
}
</script>
