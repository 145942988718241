import axios from '../../plugins/axios.js';

/**
 * @param {number} id
 * @param {object} request
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {Promise<object>}
 */
export const getReprimand = (id, request, config = undefined) => {
  return axios
    .get(route('api.v1.reprimands.show', [id]), request, config)
    .then((response) => response.data.data);
};

/**
 * @param {object} request
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {Promise<object>}
 */
export const getReprimands = (request, config = undefined) => {
  return axios
    .get(route('api.v1.reprimands.index'), { ...config, params: request })
    .then((response) => response.data);
};

/**
 * @param {number} id
 * @param {('debtor'|'creditor')} res_type
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {Promise<object>}
 */
export const getLinks = (id, res_type, config = undefined) => {
  return axios
    .get(route('api.v1.reprimands.links', [id, res_type]), config)
    .then(({ data }) => data.links);
};

/**
 * @param {number} reprimand_link_id
 * @param {('up'|'down')} direction
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {Promise<object>}
 */
export const sortLinks = (reprimand_link_id, direction, config = undefined) => {
  return axios
    .post(
      route('api.v1.reprimands.links.sort'),
      { reprimand_link_id, direction },
      config
    )
    .then(({ data }) => data.data);
};

/**
 * @param {number} reprimand_id
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getSteps = (reprimand_id, config = undefined) => {
  return axios
    .get(route('api.v1.reprimands.steps.index', [reprimand_id]), config)
    .then((response) => response.data.data);
};

/**
 * @param {number} storage_id
 * @param {object} request
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {Promise<object>}
 */
export const getStorageAttachments = (
  storage_id,
  request,
  config = undefined
) => {
  return axios
    .get(route('api.v1.storages.files.index', [storage_id]), request, config)
    .then((response) => response.data.data);
};
