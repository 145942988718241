<template>
  <v-card :class="{ 'd-print-none': !tableAttrs.items.length }">
    <VDataTableServer
      v-bind="tableAttrs"
      :row-props="rowColor"
      fixed-footer
      fixed-header
      v-on="tableListeners"
    >
      <template #top>
        <v-card-title>
          <v-row>
            <v-col cols="12" md="6">Repossession</v-col>

            <v-col cols="12" md="6" class="text-right d-print-none">
              <v-btn
                v-tooltip="'Search'"
                variant="text"
                type="submit"
                form="repossessionFilter"
                size="small"
                color="blue"
                icon="mdi-magnify"
              />

              <v-btn
                v-tooltip="filterExpand ? 'Hide filter' : 'Show filter'"
                variant="text"
                size="small"
                icon="mdi-filter"
                :color="filterExpand ? 'orange' : 'blue'"
                @click="filterExpand = !filterExpand"
              />
            </v-col>
          </v-row>

          <v-expand-transition>
            <v-form
              v-show="filterExpand"
              id="repossessionFilter"
              class="d-print-none"
              @submit.prevent="fetchItems"
            >
              <v-row>
                <v-col cols="12" md="3" lg="2">
                  <v-text-field v-model="search.ref_num" label="Ref. no." />
                </v-col>

                <v-col cols="12" md="3" lg="2">
                  <v-text-field v-model="search.dossier" label="Docket no." />
                </v-col>

                <v-col cols="12" md="3" lg="2">
                  <v-autocomplete
                    v-bind="principalAttrs"
                    label="Principal"
                    clearable
                    v-on="principalListeners"
                  />
                </v-col>

                <v-col cols="12" md="3" lg="2">
                  <v-autocomplete
                    v-bind="addresseeAttrs"
                    label="Addressee"
                    clearable
                    v-on="addresseeListeners"
                  />
                </v-col>

                <v-col cols="12" md="3" lg="2">
                  <v-autocomplete
                    v-bind="contactPersonAttrs"
                    label="Contact person"
                    clearable
                    v-on="contactPersonListeners"
                  />
                </v-col>

                <v-col cols="12" md="3" lg="2">
                  <v-select
                    v-model="search.status"
                    :items="statusOptions"
                    label="Status"
                    clearable
                    chips
                    multiple
                  />
                </v-col>

                <v-col cols="12" md="3" lg="2">
                  <v-select
                    v-model="search.complete_state"
                    :items="completedStates"
                    label="Completed state"
                    clearable
                    chips
                    multiple
                  />
                </v-col>

                <v-col cols="12" md="6" lg="4">
                  <div class="text-center">Date of authorization</div>

                  <div class="d-flex no-wrap">
                    <v-text-field
                      v-model="search.authorization_date_min"
                      type="date"
                      class="mr-2"
                      label="Min"
                      prefix=">="
                      clearable
                    />

                    <v-text-field
                      v-model="search.authorization_date_max"
                      type="date"
                      label="Max"
                      prefix="<="
                      clearable
                    />
                  </div>
                </v-col>

                <v-col cols="12" md="6" lg="4">
                  <div class="text-center">Date of completion</div>

                  <div class="d-flex no-wrap">
                    <v-text-field
                      v-model="search.created_date_min"
                      type="date"
                      class="mr-2"
                      label="Min"
                      prefix=">="
                      clearable
                    />

                    <v-text-field
                      v-model="search.created_date_max"
                      type="date"
                      label="Max"
                      prefix="<="
                      clearable
                    />
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-expand-transition>
        </v-card-title>
      </template>

      <template #[`item.ref_num`]="{ item }">
        <a
          class="text-reset font-weight-bold text-nowrap"
          :href="route('repossessions.show', [item.id])"
        >
          {{ item.ref_num }}
        </a>
      </template>

      <template #[`item.addressee`]="{ item }">
        <div v-for="link in item.links" :key="link.id">
          <template v-if="[1, 2].includes(link.responsibility)">
            <PeopleLink v-if="link.person" :person="link.person" bold />
            <DepartmentLink
              v-else-if="link.department"
              :department="link.department"
              dep
              bold
            />
          </template>
        </div>
      </template>

      <template #[`item.principal`]="{ item }">
        <div v-for="link in item.links" :key="link.id">
          <template v-if="[3, 4].includes(link.responsibility)">
            <PeopleLink v-if="link.person" :person="link.person" bold />
            <DepartmentLink
              v-else-if="link.department"
              :department="link.department"
              dep
              bold
            />
          </template>
        </div>
      </template>

      <template #[`item.contact_person`]="{ item }">
        <PeopleLink
          v-if="item.contactPerson"
          :person="item.contactPerson"
          bold
        />
      </template>

      <template #[`item.vehicle`]="{ item }">
        {{ vehicleInfo(item.vehicle) }}
      </template>

      <template #[`item.authorization_date`]="{ item }">
        <span class="text-nowrap">
          {{ formatDate(item.authorization_date, 'fullDate') }}
        </span>
      </template>

      <template #[`item.completed_date`]="{ item }">
        <span class="text-nowrap">
          {{
            item.completed_date
              ? formatDate(item.completed_date, 'fullDate')
              : 'Pending'
          }}
        </span>
      </template>

      <template #[`item.status`]="{ item }">
        <v-chip close>
          {{ getStatusText(item.statusData) }}
        </v-chip>
      </template>
    </VDataTableServer>
  </v-card>
</template>

<script setup>
import PeopleLink from '@/components/links/PeopleLink.vue';
import DepartmentLink from '@/components/links/DepartmentLink.vue';
import usePaginationTable from '@/composables/table/table-pagination.js';
import statusOptions from '@/data/repossessionStatus.json';
import completedStates from '@/data/repossessionCompletedStates.json';
import { getRepossessions } from '@/services/api/repossession.js';
import { format as formatDate } from '@/utils/formatter/date.js';
import { ref } from 'vue';
import { useLinkAutocomplete } from '@/composables/autocomplete/link-autocomplete';
import { usePersonAutocomplete } from '@/composables/autocomplete/person-autocomplete';
import { getVuetifyColor } from '@/utils/functions';

const { personId, departmentId } = defineProps({
  personId: {
    type: Number,
    required: false,
    default: null,
  },
  departmentId: {
    type: Number,
    required: false,
    default: null,
  },
});

const filterExpand = ref(false);

const search = ref({
  ref_num: null,
  dossier: null,
  department_id: null,
  person_id: null,
  addressee_person_id: null,
  addressee_department_id: null,
  principal_person_id: null,
  principal_department_id: null,
  contact_person_id: null,
  authorization_date_min: null,
  authorization_date_max: null,
  created_date_min: null,
  created_date_max: null,
  complete_state: [],
  status: [],
});

const headers = [
  { title: 'Ref. no.', key: 'ref_num' },
  { title: 'Docket no.', key: 'dossier' },
  { title: 'Principal', key: 'principal', sortable: false },
  { title: 'Addressee', key: 'addressee', sortable: false },
  // { title: 'Contact person', key: 'contact_person', sortable: false },
  { title: 'Vehicle', key: 'vehicle', sortable: false },
  { title: 'Date of authorization', key: 'authorization_date', align: 'end' },
  { title: 'Date of completion', key: 'completed_date', align: 'end' },
  { title: 'Status', key: 'status', align: 'end' },
];

/**
 * @param {object} root0
 * @param {object} root0.item
 * @returns {object}
 */
function rowColor({ item }) {
  if (!item.statusData?.status_color) {
    return {};
  }

  let color = getVuetifyColor(item.statusData.status_color);

  return {
    class: {
      [color]: true,
    },
  };
}

const {
  link: addresseeObject,
  attrs: addresseeAttrs,
  listeners: addresseeListeners,
} = useLinkAutocomplete();

const {
  link: principalObject,
  attrs: principalAttrs,
  listeners: principalListeners,
} = useLinkAutocomplete();

const { attrs: contactPersonAttrs, listeners: contactPersonListeners } =
  usePersonAutocomplete();

function updateSearch() {
  search.value.addressee_person_id = null;
  search.value.addressee_department_id = null;
  search.value.principal_person_id = null;
  search.value.principal_department_id = null;
  search.value.person_id = personId;
  search.value.department_id = departmentId;

  if (addresseeObject?.type && addresseeObject.id) {
    if (addresseeObject?.type == 'person') {
      search.value.addressee_person_id = addresseeObject.id;
    } else if (addresseeObject?.type == 'department') {
      search.value.addressee_department_id = addresseeObject.id;
    }
  }

  if (principalObject?.type && principalObject.id) {
    if (principalObject?.type == 'person') {
      search.value.principal_person_id = principalObject.id;
    } else if (principalObject?.type == 'department') {
      search.value.principal_department_id = principalObject.id;
    }
  }
}

const {
  attrs: tableAttrs,
  listeners: tableListeners,
  fetchItems,
} = usePaginationTable({
  headers,
  sortBy: [],
  fetchRequest: getRepossessions,
  prepareSearch() {
    updateSearch();

    return {
      ...search.value,
    };
  },
});

/**
 * @param {object} vehicle
 * @returns {string|null}
 */
function vehicleInfo(vehicle) {
  if (!vehicle) {
    return null;
  }

  return [
    vehicle.make,
    vehicle.model,
    vehicle.color,
    vehicle.year,
    vehicle.chassis_number,
  ]
    .filter((text) => text)
    .join('; ');
}

/**
 * @param {(object|null)} status
 * @returns {string}
 */
function getStatusText(status) {
  if (!status) {
    return 'Unknown';
  }

  let statusOption = statusOptions.find(
    (option) => option.value == status.value
  );

  if (statusOption) {
    return statusOption.abbr || statusOption.title;
  }

  return status.status_name;
}

fetchItems();
</script>
