<template>
  <v-card :class="{ 'd-print-none': !tableAttrs.items.length }">
    <v-data-table-server
      v-bind="tableAttrs"
      color="red"
      :row-props="rowColor"
      fixed-header
      v-on="tableListeners"
    >
      <template #top>
        <v-card-title>
          <v-row>
            <v-col cols="12" md="6">Collections cases</v-col>

            <v-col cols="12" md="6" class="text-right d-print-none">
              <DebtFormModal
                v-if="
                  $gates.hasPermission('debt.create') &&
                  $gates.hasPermission('user.is_employee') &&
                  (personId || departmentId)
                "
                class="m-1"
                :person-id="personId"
                :department-id="departmentId"
              />

              <v-btn
                v-tooltip="'Search'"
                type="submit"
                form="debtFilters"
                size="small"
                color="blue"
                icon="mdi-magnify"
                variant="text"
                @click="fetchItems"
              />

              <v-btn
                v-if="
                  $gates.hasPermission('debt.create') &&
                  !$gates.hasPermission('user.is_employee')
                "
                v-tooltip="'Add debt collection'"
                variant="text"
                size="small"
                color="green"
                icon="mdi-plus"
                :href="route('debts.client.create-form')"
              />

              <v-btn
                v-can="'user.is_employee'"
                v-tooltip="'Export'"
                variant="text"
                size="small"
                color="green"
                icon="mdi-file-excel"
                @click="exportExcel"
              />

              <v-btn
                v-tooltip="filterExpand ? 'Hide filter' : 'Show filter'"
                size="small"
                :color="filterExpand ? 'orange' : 'blue'"
                icon="mdi-filter"
                variant="text"
                @click="filterExpand = !filterExpand"
              />
            </v-col>
          </v-row>

          <v-expand-transition>
            <v-form
              v-show="filterExpand"
              id="debtFilters"
              class="d-print-none"
              @submit.prevent="fetchItems"
            >
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field v-model="search.ref_num" label="Ref. nummer" />
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field v-model="search.dossier" label="Dossier" />
                </v-col>

                <v-col cols="12" md="3">
                  <v-autocomplete
                    v-bind="debtorAttrs"
                    label="Debiteur"
                    clearable
                    v-on="debtorListeners"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-autocomplete
                    v-bind="creditorAttrs"
                    label="Crediteur"
                    clearable
                    v-on="creditorListeners"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    v-model="search.status"
                    :items="statusOptions"
                    label="Status"
                    clearable
                    chips
                    multiple
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    v-model="search.rental"
                    :items="debtTypesOptions"
                    label="Type"
                    clearable
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    v-model="search.physical_locations"
                    :items="fileLocations"
                    label="File location"
                    clearable
                    chips
                    multiple
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    v-model="extraHeaders"
                    :items="debtCalculationsItems"
                    label="Columns"
                    clearable
                    chips
                    multiple
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-expand-transition>
        </v-card-title>
      </template>

      <template
        v-for="header in headers.filter((header) =>
          header.hasOwnProperty('formatter')
        )"
        #[`item.${header.key}`]="{ item }"
      >
        {{ header.formatter(getFromDotNotation(item, header.key)) }}
      </template>

      <template #[`item.status`]="{ item }">
        <v-chip close>
          {{ getStatusText(item.status_data) }}
        </v-chip>
      </template>

      <template #[`item.ref_num`]="{ item }">
        <a
          class="text-reset font-weight-bold text-nowrap"
          :href="route('debts.show', [item.id])"
        >
          {{ item.ref_num }}
        </a>
      </template>

      <template #[`item.debtor`]="{ item }">
        <div v-for="link in item.links" :key="link.id">
          <template v-if="[1, 2].includes(link.responsibility)">
            <PeopleLink v-if="link.person" :person="link.person" bold />
            <DepartmentLink
              v-else-if="link.department"
              :department="link.department"
              bold
            />
          </template>
        </div>
      </template>

      <template #[`item.creditor`]="{ item }">
        <div v-for="link in item.links" :key="link.id">
          <template v-if="[3, 4].includes(link.responsibility)">
            <PeopleLink v-if="link.person" :person="link.person" bold />
            <DepartmentLink
              v-else-if="link.department"
              :department="link.department"
              bold
            />
          </template>
        </div>
      </template>

      <template #tfoot="{ columns }">
        <tfoot>
          <tr>
            <th v-for="header in columns" :key="header.key" class="text-right">
              {{ getTFootText(header) }}
            </th>
          </tr>
        </tfoot>
      </template>
    </v-data-table-server>
  </v-card>
</template>

<script setup>
import PeopleLink from '@/components/links/PeopleLink.vue';
import DepartmentLink from '@/components/links/DepartmentLink.vue';

import statusOptions from '@/data/debtStatus.js';
import fileLocations from '@/data/fileLocations';
import debtCalculations from '@/data/debtCalculations.json';
import { useLinkAutocomplete } from '@/composables/autocomplete/link-autocomplete.js';
import usePaginationTable from '@/composables/table/table-pagination.js';

import { getFromDotNotation, getVuetifyColor } from '@/utils/functions.js';
import { getDebts } from '@/services/api/debt.js';
import { formatMoney } from '@/utils/formatter/number.js';

import { ref, computed } from 'vue';

const { personId, departmentId } = defineProps({
  personId: {
    type: Number,
    required: false,
    default: null,
  },
  departmentId: {
    type: Number,
    required: false,
    default: null,
  },
});

/**
 * @param {object} root0
 * @param {object} root0.item
 * @returns {object}
 */
function rowColor({ item }) {
  if (!item.status_data?.status_color) {
    return {};
  }

  let color = getVuetifyColor(item.status_data.status_color);

  return {
    class: {
      [color]: true,
    },
  };
}

// loop through json
const debtCalculationsItems = Object.entries(debtCalculations).map(
  ([index, item]) => {
    return {
      value: index,
      title: item,
    };
  }
);

const debtTypesOptions = [
  { value: 0, title: 'Debt collection' },
  { value: 1, title: 'Regular collection' },
];

const filterExpand = ref(false);

const search = ref({
  ref_num: null,
  dossier: null,
  status: [],
  rental: null,
  physical_locations: [],
  debtor_person_id: null,
  creditor_person_id: null,
  debtor_department_id: null,
  creditor_department_id: null,
  person_id: null,
  department_id: null,
  sortBy: [],
});

const {
  link: debtorObject,
  attrs: debtorAttrs,
  listeners: debtorListeners,
} = useLinkAutocomplete();

const {
  link: creditorObject,
  attrs: creditorAttrs,
  listeners: creditorListeners,
} = useLinkAutocomplete();

const extraHeaders = ref([
  'contract',
  'grand_total',
  'open_balance',
  'late_payment',
]);

const integerColumns = [
  'agreement_count',
  'notice_letter_count',
  'advice_letter_count',
];

const headers = computed(() => {
  let selectedExtraHeaders = extraHeaders.value.map((column) => {
    let foundColumn = debtCalculations[column];
    let isInteger = integerColumns.includes(column);

    return {
      title: foundColumn,
      key: `calculations.${column}`,
      align: 'end',
      formatter: isInteger ? ($value) => $value : formatMoney,
    };
  });

  return [
    { title: 'Ref. nummer', key: 'ref_num' },
    { title: 'Dossiernummer', key: 'dossier' },
    { title: 'Crediteur', key: 'creditor', sortable: false },
    { title: 'Debiteur', key: 'debtor', sortable: false },
    { title: 'Status', key: 'status', with: '150' },
    { title: 'File location', key: 'physical_location', with: '150' },
    ...selectedExtraHeaders,
  ];
});

const {
  attrs: tableAttrs,
  listeners: tableListeners,
  fetchItems,
} = usePaginationTable({
  headers: headers,
  sortBy: [
    { key: 'status', order: 'asc' },
    { key: 'ref_num', order: 'desc' },
  ],
  fetchRequest: getDebts,
  prepareSearch() {
    updateSearch();

    return {
      ...search.value,
    };
  },
});

function updateSearch() {
  search.value.debtor_person_id = null;
  search.value.debtor_department_id = null;
  search.value.creditor_person_id = null;
  search.value.creditor_department_id = null;
  search.value.person_id = personId;
  search.value.department_id = departmentId;

  if (debtorObject?.type && debtorObject.id) {
    if (debtorObject?.type == 'person') {
      search.value.debtor_person_id = debtorObject.id;
    } else if (debtorObject?.type == 'department') {
      search.value.debtor_department_id = debtorObject.id;
    }
  }

  if (creditorObject?.type && creditorObject.id) {
    if (creditorObject?.type == 'person') {
      search.value.creditor_person_id = creditorObject.id;
    } else if (creditorObject?.type == 'department') {
      search.value.creditor_department_id = creditorObject.id;
    }
  }
}

function exportExcel() {
  let url = new URL('/debts/export', window.location.origin);

  updateSearch();

  Object.keys(search.value).forEach((key) => {
    if (!['sortBy'].includes(key) && search.value[key]) {
      url.searchParams.append(key, search.value[key]);
    }
  });

  open(url, '_blank');
}

/**
 * @param {(object|null)} status
 * @returns {string}
 */
function getStatusText(status) {
  if (!status) {
    return 'Unknown';
  }

  let statusOption = statusOptions.find(
    (option) => option.value == status.status_id
  );

  if (statusOption) {
    return statusOption.abbr;
  }

  return status.status_name;
}

/**
 * @param {object} header
 * @param {string} header.key
 * @returns {string}
 */
function getTFootText(header) {
  if (header.key.includes('calculations.')) {
    const key = header.key.replace('calculations.', '');
    let total = 0;

    tableAttrs.items.forEach((item) => {
      total += item.calculations[key];
    });

    if (header.formatter) {
      return header.formatter(total);
    }

    return total;
  }

  return '';
}
</script>
