<template>
  <BaseModal v-model="model" size="xl">
    <template #activator="{ props }">
      <slot name="activator" :props="props">
        <v-btn
          v-if="!noButton"
          v-tooltip="'See attachment'"
          v-bind="props"
          color="gray"
          variant="text"
          density="default"
        >
          <v-badge :content="counter" color="red" floating>
            <v-icon icon="mdi-file"></v-icon>
          </v-badge>
        </v-btn>

        <span
          v-if="adapter"
          :id="'adapter-' + $attrs.id"
          class="d-none"
          data-adapter="{}"
          @click="injectData"
        />
      </slot>
    </template>

    <v-card-text>
      <AttachmentCard
        v-if="!adapter"
        :section="section"
        :section-id="sectionId"
        :child="child"
        :child-id="childId"
      />
      <AttachmentCard
        v-else
        :section="adapterData.section"
        :section-id="adapterData.sectionId"
        :child="adapterData.child"
        :child-id="adapterData.childId"
      />
    </v-card-text>
  </BaseModal>
</template>

<script setup>
import AttachmentCard from '@/components/card/general/AttachmentCard.vue';
import BaseModal from '@/components/general/BaseModal.vue';
import useInjectDataAdapter from '@/composables/injectDataAdapter.js';
import {} from '@/composables/injectDataAdapter';
import { getCurrentInstance, nextTick } from 'vue';

const { adapter } = defineProps({
  sectionId: {
    type: Number,
    required: true,
  },

  section: {
    type: String,
    required: true,
  },

  childId: {
    type: Number,
    default: null,
  },

  child: {
    type: String,
    default: null,
  },

  noButton: {
    type: Boolean,
    default: false,
  },

  adapter: {
    type: Boolean,
    default: false,
  },

  counter: {
    type: Number,
    default: 0,
  },
});

const model = defineModel('model', {
  type: Boolean,
  default: false,
});

const changeContext = (values, adapterData) => {
  if (values.child) {
    adapterData.child = values.child;
  }

  if (values.section) {
    adapterData.section = values.section;
  }

  if (values.childId) {
    adapterData.childId = values.childId;
  }

  if (values.sectionId) {
    adapterData.sectionId = values.sectionId;
  }

  model.value = true;
};

const vm = getCurrentInstance();

const { adapterData, injectData } = useInjectDataAdapter(
  {
    section: null,
    sectionId: null,
    child: null,
    childId: null,
  },
  changeContext,
  vm.attrs.id
);
</script>
