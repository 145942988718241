<template>
  <v-row>
    <v-col cols="12" style="border-bottom: 1px solid black" class="mb-4">
      <img
        src="/resources/images/RSI_Header.png"
        alt="RSI Logo"
        class="w-100 pb-1"
      />
    </v-col>
  </v-row>
</template>
