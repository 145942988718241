<template>
  <v-card>
    <v-card-title class="text-center">
      Case information

      <span class="float-right d-print-none">
        <AttachmentCardModal
          v-if="reprimand.id && $gates.hasPermission('reprimand.file.upload')"
          section="reprimand"
          :section-id="reprimand.id"
          :counter="reprimand.attachment_counter"
        />

        <v-menu>
          <template #activator="{ props: aProps }">
            <v-btn icon="mdi-dots-vertical" v-bind="aProps" variant="text" />
          </template>

          <v-list>
            <v-list-item>
              <v-btn
                variant="text"
                text="Refresh"
                block
                @click="refreshReprimand"
              />
            </v-list-item>

            <v-list-item>
              <!-- @todo: is not working -->
              <v-btn variant="text" text="Notification" block />
            </v-list-item>

            <v-list-item v-can="'reprimand.download_declaration'">
              <v-btn
                v-tooltip="'Download declaration'"
                variant="text"
                color="blue"
                text="Declaration"
                block
                target="_blank"
                :href="'/generated/reprimand_declaration/' + reprimand.id"
              />
            </v-list-item>

            <v-list-item v-can="'reprimand.update'">
              <v-btn
                variant="text"
                color="blue"
                data-target="btn-reprimand"
                data-ajax-type="edit"
                :data-reprimand-id="reprimand.id"
                text="Edit"
                block
              />
            </v-list-item>

            <v-list-item v-can="'reprimand.delete'">
              <v-btn
                id="btn-delete-modal"
                variant="text"
                color="red"
                text="Delete"
                delelement="reprimand"
                :elementid="reprimand.id"
                block
              />
            </v-list-item>
          </v-list>
        </v-menu>
      </span>
    </v-card-title>

    <v-card-text>
      <v-table>
        <tbody>
          <tr>
            <th>Reference number</th>
            <td class="font-weight-bold">
              {{ reprimand.ref_num }}
            </td>
          </tr>

          <tr v-if="reprimand.dossier">
            <th scope="row">Docket number</th>
            <td>{{ reprimand.dossier }}</td>
          </tr>

          <tr v-if="reprimand.contactPerson">
            <th scope="row">Contact Person</th>
            <td>
              <PeopleLink :person="reprimand.contactPerson" bold />
            </td>
          </tr>

          <tr>
            <th>
              Principal
              <v-btn
                v-can="'reprimand.link.create'"
                v-tooltip="'Link to principal'"
                size="small"
                icon="mdi-plus"
                class="float-right d-print-none"
                data-ajax-type="newRC"
                data-target="btn-add-link"
                :data-reprimand-id="reprimand.id"
              />
            </th>

            <td>
              <LinkList
                v-if="reprimand.id"
                :section-id="reprimand.id"
                res-type="creditor"
                section="reprimand"
              />
            </td>
          </tr>

          <tr>
            <th>
              Addressee(s)
              <v-btn
                v-can="'reprimand.link.create'"
                v-tooltip="'Link to addressee'"
                size="small"
                icon="mdi-plus"
                class="float-right d-print-none"
                data-ajax-type="newRD"
                data-target="btn-add-link"
                :data-reprimand-id="reprimand.id"
              />
            </th>

            <td>
              <LinkList
                v-if="reprimand.id"
                :section-id="reprimand.id"
                res-type="debtor"
                section="reprimand"
              />
            </td>
          </tr>

          <tr>
            <th>Status</th>
            <td>{{ reprimand.status?.name }}</td>
          </tr>

          <tr v-if="reprimand.receive_type">
            <th>Completed</th>
            <td>
              <span class="font-italic"
                >{{ reprimand.receive_type.name }} on</span
              >
              <span class="font-weight-bold">{{
                formatDate(reprimand.completed_date, 'fullDate')
              }}</span>
            </td>
          </tr>

          <tr v-if="reprimand.policies">
            <th>Policies</th>
            <td>
              <span
                v-for="policy in reprimand.policies"
                :key="policy.id"
                class="d-block"
              >
                {{ policy.policy_no }}
              </span>
            </td>
          </tr>

          <template v-if="reprimand.ultimatum_date">
            <tr>
              <th>Served to</th>
              <td class="font-weight-bold font-italic">
                {{ reprimand.received_by }}
              </td>
            </tr>

            <tr>
              <th>Ultimatum</th>
              <td>
                <template v-if="reprimand.new_ultimatum_date">
                  <span class="font-weight-bold"> Original: </span>
                  {{ formatDate(reprimand.ultimatum_date, 'fullDate') }}

                  <span class="font-weight-bold"> New: </span>
                  {{ formatDate(reprimand.new_ultimatum_date, 'fullDate') }}
                </template>
                <span v-else>
                  {{ formatDate(reprimand.ultimatum_date, 'fullDate') }}
                </span>
              </td>
            </tr>
          </template>

          <tr v-else>
            <th>Number of days deadline</th>
            <td>
              {{ reprimand.ultimatum_days }}
              {{ reprimand.workdays ? 'Working days' : 'Days' }}
            </td>
          </tr>

          <tr v-if="reprimand.letter_type">
            <th>Letter type</th>
            <td>{{ reprimand.letter_type }}</td>
          </tr>

          <tr
            v-if="
              reprimand.physical_location &&
              $gates.hasPermission('user.is_employee')
            "
            class="d-print-none"
          >
            <th>Physical location</th>
            <td>{{ reprimand.physical_location }}</td>
          </tr>

          <tr>
            <th>Receipt date</th>
            <td>{{ formatDate(reprimand.receive_task_date, 'fullDate') }}</td>
          </tr>

          <tr
            v-if="
              reprimand.createdBy && $gates.hasPermission('user.is_employee')
            "
          >
            <th>Created by</th>
            <td>
              <UserLink :user="reprimand.createdBy" bold />
            </td>
          </tr>

          <tr
            v-if="
              reprimand.created_at && $gates.hasPermission('user.is_employee')
            "
          >
            <th>Register date (RIP)</th>
            <td>{{ formatDate(reprimand.created_at, 'fullDate') }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import PeopleLink from '@/components/links/PeopleLink.vue';
import LinkList from '@/components/general/LinksList.vue';
import { reprimand, fetchReprimand } from '@/store/reprimand/reprimand.js';
import { format as formatDate } from '@/utils/formatter/date.js';
import AttachmentCardModal from '@/components/modal/general/AttachmentCardModal.vue';
import UserLink from '@/components/links/UserLink.vue';

function refreshReprimand() {
  if (reprimand.id) {
    fetchReprimand(reprimand.id);
  }
}
</script>
