<template>
  <v-card :class="{ 'd-print-none': !tableAttrs.items.length }">
    <VDataTableServer
      v-bind="tableAttrs"
      :row-props="rowColor"
      fixed-footer
      fixed-header
      v-on="tableListeners"
    >
      <template #top>
        <v-card-title>
          <v-row>
            <v-col cols="12" md="6">Storage</v-col>

            <v-col cols="12" md="6" class="text-right d-print-none">
              <v-btn
                v-tooltip="'Search'"
                variant="text"
                type="submit"
                form="storageFilter"
                size="small"
                color="blue"
                icon="mdi-magnify"
              />

              <v-btn
                v-tooltip="filterExpand ? 'Hide filter' : 'Show filter'"
                variant="text"
                size="small"
                icon="mdi-filter"
                :color="filterExpand ? 'orange' : 'blue'"
                @click="filterExpand = !filterExpand"
              />
            </v-col>
          </v-row>

          <v-expand-transition>
            <v-form
              v-show="filterExpand"
              id="storageFilter"
              class="d-print-none"
              @submit.prevent="fetchItems"
            >
              <v-row>
                <v-col cols="12" md="3" lg="2">
                  <v-text-field v-model="search.ref_num" label="Ref. no." />
                </v-col>

                <v-col cols="12" md="3" lg="2">
                  <v-text-field v-model="search.dossier" label="Docket no." />
                </v-col>

                <v-col cols="12" md="3" lg="2">
                  <v-autocomplete
                    v-bind="principalAttrs"
                    label="Principal"
                    clearable
                    v-on="principalListeners"
                  />
                </v-col>

                <v-col cols="12" md="3" lg="2">
                  <v-autocomplete
                    v-bind="contactPersonAttrs"
                    label="Contact person"
                    clearable
                    v-on="contactPersonListeners"
                  />
                </v-col>

                <v-col cols="12" md="3" lg="2">
                  <v-text-field v-model="search.vehicle" label="Vehicle" />
                </v-col>

                <v-col cols="12" md="6" lg="4">
                  <div class="text-center">Date taken into storage</div>

                  <div class="d-flex no-wrap">
                    <v-text-field
                      v-model="search.in_date_min"
                      type="date"
                      class="mr-2"
                      label="Min"
                      prefix=">="
                      clearable
                    />

                    <v-text-field
                      v-model="search.in_date_max"
                      type="date"
                      label="Max"
                      prefix="<="
                      clearable
                    />
                  </div>
                </v-col>

                <v-col cols="12" md="6" lg="4">
                  <div class="text-center">Date released from storage</div>

                  <div class="d-flex no-wrap">
                    <v-text-field
                      v-model="search.out_date_min"
                      type="date"
                      class="mr-2"
                      label="Min"
                      prefix=">="
                      clearable
                    />

                    <v-text-field
                      v-model="search.out_date_max"
                      type="date"
                      label="Max"
                      prefix="<="
                      clearable
                    />
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-expand-transition>
        </v-card-title>
      </template>

      <template #[`item.ref_num`]="{ item }">
        <a
          class="text-reset font-weight-bold text-nowrap"
          :href="route('storages.show', [item.id])"
        >
          {{ item.ref_num }}
        </a>
      </template>

      <template #[`item.principal`]="{ item }">
        <PeopleLink v-if="item.person" :person="item.person" bold />
        <DepartmentLink
          v-if="item.department"
          :department="item.department"
          dep
          bold
        />
      </template>

      <template #[`item.contact_person`]="{ item }">
        <PeopleLink
          v-if="item.contactPerson"
          :person="item.contactPerson"
          bold
        />
      </template>

      <template #[`item.vehicle`]="{ item }">
        {{ vehicleInfo(item.vehicle) }}
      </template>

      <template #[`item.in_date`]="{ item }">
        <span class="text-nowrap">
          {{ formatDate(item.in_date, 'fullDate') }}
        </span>
      </template>

      <template #[`item.out_date`]="{ item }">
        <span class="text-nowrap">
          {{
            item.out_date
              ? formatDate(item.out_date, 'fullDate')
              : 'Still in storage'
          }}
        </span>
      </template>
    </VDataTableServer>
  </v-card>
</template>

<script setup>
import PeopleLink from '@/components/links/PeopleLink.vue';
import DepartmentLink from '@/components/links/DepartmentLink.vue';
import usePaginationTable from '@/composables/table/table-pagination.js';
import { getStorages } from '@/services/api/storage.js';
import { format as formatDate } from '@/utils/formatter/date.js';
import { ref } from 'vue';
import { useLinkAutocomplete } from '@/composables/autocomplete/link-autocomplete';
import { usePersonAutocomplete } from '@/composables/autocomplete/person-autocomplete';

const filterExpand = ref(false);

const search = ref({
  ref_num: null,
  dossier: null,
  person_id: null,
  department_id: null,
  contact_person_id: null,
  in_date_min: null,
  in_date_max: null,
  out_date_min: null,
  out_date_max: null,
});

const headers = [
  { title: 'Ref. no.', key: 'ref_num' },
  { title: 'Docket no.', key: 'dossier' },
  { title: 'Principal', key: 'principal', sortable: false },
  { title: 'Contact person', key: 'contact_person', sortable: false },
  { title: 'Section', key: 'section', sortable: false },
  { title: 'Vehicle', key: 'vehicle', sortable: false },
  { title: 'Days over-invoiced', key: 'extra_periods', align: 'end' },
  { title: 'Days not invoiced', key: 'periods', align: 'end' },
  { title: 'Days total', key: 'total_days', align: 'end', sortable: false },
  { title: 'Date taken', key: 'in_date', align: 'end' },
  { title: 'Date released', key: 'out_date', align: 'end' },
];

/**
 * @param {object} root0
 * @param {object} root0.item
 * @returns {object}
 */
function rowColor({ item }) {
  return {
    class: {
      'bg-amber-lighten-4': item.open_period == 1 && item.extra_periods >= 0,
      'bg-red-lighten-4': item.extra_periods < 0,
    },
  };
}

const {
  link: principalObject,
  attrs: principalAttrs,
  listeners: principalListeners,
} = useLinkAutocomplete();

const { attrs: contactPersonAttrs, listeners: contactPersonListeners } =
  usePersonAutocomplete();

function updateSearch() {
  search.value.person_id = null;
  search.value.department_id = null;
  search.value.contact_person_id = contactPersonAttrs.modelValue;

  if (principalObject?.type && principalObject.id) {
    if (principalObject?.type == 'person') {
      search.value.person_id = principalObject.id;
    } else if (principalObject?.type == 'department') {
      search.value.department_id = principalObject.id;
    }
  }
}

const {
  attrs: tableAttrs,
  listeners: tableListeners,
  fetchItems,
} = usePaginationTable({
  headers,
  sortBy: [],
  fetchRequest: getStorages,
  prepareSearch() {
    updateSearch();

    return {
      ...search.value,
    };
  },
});

/**
 * @param {object} vehicle
 * @returns {string|null}
 */
function vehicleInfo(vehicle) {
  if (!vehicle) {
    return null;
  }

  return [
    vehicle.make,
    vehicle.model,
    vehicle.color,
    vehicle.year,
    vehicle.chassis_number,
  ]
    .filter((text) => text)
    .join('; ');
}

fetchItems();
</script>
