<template>
  <v-card>
    <VDataTableServer
      v-bind="tableAttrs"
      height="70vh"
      fixed-footer
      fixed-header
      v-on="tableListeners"
    >
      <template #top>
        <v-card-title>
          <v-row>
            <v-col cols="12" md="6">Roles</v-col>
            <v-col cols="12" md="6" class="text-right">
              <!--
              <BaseModal v-model="addRoleModal">
                <template #activator="{ props }">
                  <v-btn color="success" v-bind="props">
                    <v-icon icon="mdi-plus" left size="small" />
                    Add role
                  </v-btn>
                </template>
                <RoleForm />
              </BaseModal>
              -->

              <v-btn
                id="btn-role"
                size="small"
                color="success"
                data-ajax-type="new"
              >
                <v-icon icon="mdi-plus" left />
                Add role
              </v-btn>

              <v-btn size="small" color="blue" @click="fetchItems">
                Search
              </v-btn>
            </v-col>
          </v-row>

          <v-form @submit.prevent="fetchItems">
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field v-model="search.name" label="Name" />
              </v-col>
            </v-row>
          </v-form>
        </v-card-title>
      </template>

      <template #[`item.permissions`]="{ item }">
        {{ item.permissions.length }}
      </template>

      <template #[`item.is_employee`]="{ item }">
        <v-icon
          v-if="item.permissions.includes('user.is_employee')"
          color="success"
          icon="mdi-check-bold"
        />
        <v-icon v-else color="error" icon="mdi-close-thick" />
      </template>

      <template #[`item.is_client`]="{ item }">
        <v-icon
          v-if="item.permissions.includes('user.is_client')"
          color="success"
          icon="mdi-check-bold"
        />
        <v-icon v-else color="error" icon="mdi-close-thick" />
      </template>

      <template #[`item.actions`]="{ item }">
        <v-menu>
          <template #activator="{ props }">
            <v-btn icon="mdi-dots-vertical" v-bind="props" variant="text" />
          </template>

          <v-list>
            <v-list-item>
              <v-btn
                id="btn-role"
                block
                variant="text"
                data-ajax-type="edit"
                :data-role-id="item.id"
              >
                Edit
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn
                id="btn-delete-modal"
                block
                variant="text"
                delelement="rol"
                :elementid="item.id"
              >
                Remove
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </VDataTableServer>
  </v-card>
</template>

<script setup>
// import RoleForm from '@/components/form/user/RoleForm.vue';
// import BaseModal from '@/components/general/BaseModal.vue';
import usePaginationTable from '@/composables/table/table-pagination.js';

import { getRoles } from '@/services/api/user.js';
import { ref } from 'vue';

const search = ref({
  name: null,
});

const headers = [
  { title: 'ID', key: 'id' },
  { title: 'Name', key: 'name' },
  { title: 'Permissions', key: 'permissions', sortable: false },
  { title: 'Users', key: 'user_count', sortable: false },
  { title: 'Client', key: 'is_client', sortable: false },
  { title: 'Employee', key: 'is_employee', sortable: false },
  { title: 'Actions', key: 'actions', sortable: false, align: 'end' },
];

const {
  attrs: tableAttrs,
  listeners: tableListeners,
  fetchItems,
} = usePaginationTable({
  headers,
  sortBy: [{ key: 'name', order: 'asc' }],
  fetchRequest: getRoles,
  prepareSearch() {
    return {
      ...search.value,
    };
  },
});
</script>
