<template>
  <BaseModal v-model="openTaskModal" @close="openTaskModal = null">
    <TaskCard v-if="selectedTask" :task-id="selectedTask" />
  </BaseModal>

  <BaseModal v-model="openStepModal" @close="openStepModal = null">
    <TaskStepCard
      v-if="selectedStep"
      :task-id="selectedStep"
      :section="section"
      @task-completed="stepCompleted"
    />
  </BaseModal>

  <v-card>
    <v-card-title class="text-center">
      <span
        v-can:unless="section + '.task.declaration.view'"
        class="float-left"
      >
        <v-icon
          v-tooltip="'You don\'t have permissions to see declarations'"
          size="xs"
          color="warning"
          >mdi-lock</v-icon
        >
      </span>
      Step and narrative

      <span class="float-right d-print-none">
        <DeleteButton :url="rollbackUrl" @deleted="emitRollback">
          <template #activator="{ props: aProps }">
            <v-btn
              v-can="section + '.task.rollback'"
              v-tooltip="'Rollback last step'"
              v-bind="aProps"
              color="red"
              variant="text"
              icon="mdi-undo"
            />
          </template>
        </DeleteButton>
      </span>
    </v-card-title>

    <v-card-text>
      <div v-can:unless="section + '.task.view'" class="text-center">
        You don't have permissions to see the steps
      </div>

      <v-table v-can="section + '.task.view'">
        <tbody>
          <template v-for="taskStep in steps" :key="taskStep.id">
            <tr class="border-b-md border-dark">
              <th>
                <span v-bind="openTaskAttrs(taskStep.id)">
                  {{ taskStep.name }}
                </span>

                <!-- @todo: need to make the task form -->
                <v-btn
                  v-can="'user.is_admin'"
                  class="d-none"
                  color="green"
                  variant="text"
                  icon="mdi-tooltip-edit"
                  @click="openTask(taskStep)"
                />
              </th>

              <td>
                <UserLink :user="taskStep.taskFor" bold />
              </td>

              <td class="text-right">
                {{ formatDate(taskStep.action_date, 'fullDate') || 'Pending' }}
              </td>

              <td v-can:any="actionPermissions" class="text-right d-print-none">
                <v-btn-group>
                  <v-btn
                    v-if="!taskStep.completed_at"
                    v-can="section + '.task.complete'"
                    v-tooltip="'Complete step'"
                    color="green"
                    variant="text"
                    icon="mdi-check"
                    @click="completeStep(taskStep)"
                  />

                  <!-- disabled for impounding  -->
                  <v-btn
                    v-if="section !== 'impound'"
                    v-can="section + '.task.declaration.create'"
                    v-tooltip="'Add declaration'"
                    variant="text"
                    icon="mdi-message-plus"
                    data-target="btn-reprimand-action-note"
                    data-ajax-type="new"
                    :data-task-id="taskStep.id"
                  />
                </v-btn-group>
              </td>
            </tr>
            <tr
              v-if="taskStep.description"
              v-can="section + '.task.declaration.view'"
              class="font-italic"
            >
              <td colspan="4" class="pl-5">{{ taskStep.description }}</td>
            </tr>
            <template
              v-if="$gates.hasPermission(section + '.task.declaration.view')"
            >
              <template
                v-for="declaration in taskStep.declarations"
                :key="declaration.id"
              >
                <tr class="bg-amber-lighten-3">
                  <td class="font-weight-bold pl-5">
                    <v-icon>mdi-menu-right</v-icon>
                    Declaration
                  </td>

                  <td>
                    <UserLink :user="declaration.actionBy" bold />
                  </td>

                  <td class="text-right">
                    {{
                      declaration.time ? formatTime(declaration.time) + ';' : ''
                    }}
                    {{ formatDate(declaration.date, 'fullDate') }}
                  </td>

                  <td
                    v-can:any="actionPermissions"
                    class="text-right d-print-none"
                  >
                    <v-btn-group>
                      <!-- @todo: Legacy, has to be refactor -->
                      <v-btn
                        v-can="section + '.task.declaration.update'"
                        v-tooltip="'Edit declaration'"
                        color="blue"
                        variant="text"
                        size="small"
                        icon="mdi-pencil"
                        data-target="btn-reprimand-action-note"
                        data-ajax-type="edit"
                        :data-action-note-id="declaration.id"
                      />

                      <!-- @todo: Legacy, has to be refactor -->
                      <v-btn
                        id="btn-delete-modal"
                        v-can="section + '.task.declaration.delete'"
                        v-tooltip="'Delete declaration'"
                        size="small"
                        variant="text"
                        color="red"
                        icon="mdi-delete"
                        delelement="reprimand_action_note"
                        :elementid="declaration.id"
                      />
                    </v-btn-group>
                  </td>
                </tr>

                <tr>
                  <td
                    colspan="4"
                    class="bg-amber-lighten-4 font-italic pl-5 text-pre-line"
                  >
                    {{ declaration.note }}
                  </td>
                </tr>
              </template>
            </template>
          </template>
        </tbody>
      </v-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import UserLink from '@/components/links/UserLink.vue';
import TaskCard from '@/components/card/general/TaskCard.vue';
import BaseModal from '@/components/general/BaseModal.vue';
import TaskStepCard from '@/components/card/general/TaskStepCard.vue';

import { getSteps as getImpoundSteps } from '@/services/api/impound.js';
import { getSteps as getReprimandSteps } from '@/services/api/reprimand.js';
import { computed, ref, inject, onMounted } from 'vue';
import { format as formatDate, formatTime } from '@/utils/formatter/date.js';
import DeleteButton from '@/components/general/DeleteButton.vue';

const { sectionId, section } = defineProps({
  sectionId: {
    type: Number,
    required: true,
  },

  section: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(['step-completed', 'rollback-step']);

const $gates = inject('$gates');

const actionPermissions = computed(() => {
  return section + '.task.complete|' + section + '.task.declaration.create';
});

const hasSomeViewPermissions = computed(() => {
  return (
    !$gates.hasPermission(section + '.task.view') ||
    !$gates.hasPermission(section + '.task.declaration.view')
  );
});

const rollbackUrl = computed(() => {
  return route('api.v1.' + section + 's.steps.rollback', [sectionId]);
});

const steps = ref([]);
const selectedTask = ref(null);
const openTaskModal = ref(false);
const selectedStep = ref(null);
const openStepModal = ref(false);

function fetchSteps() {
  if (!$gates.hasPermission(section + '.task.view')) {
    return;
  }

  let promise = null;

  if (section === 'impound') {
    promise = getImpoundSteps(sectionId);
  } else if (section === 'reprimand') {
    promise = getReprimandSteps(sectionId);
  }

  promise?.then((_steps) => {
    steps.value = _steps;
  });
}

/**
 * @param {object} step
 */
function openTask(step) {
  selectedTask.value = step.id;
  openTaskModal.value = true;
}

/**
 * @param {object} step
 */
function completeStep(step) {
  selectedStep.value = step.id;
  openStepModal.value = true;
}

function stepCompleted() {
  openStepModal.value = false;
  fetchSteps();
  emits('step-completed');
}

function emitRollback() {
  fetchSteps();
  emits('rollback-step');
}

/**
 * @param {number} taskId
 * @returns {object}
 */
function openTaskAttrs(taskId) {
  // @todo: disable momentary
  if (!$gates.hasPermission(section + '.task.view')) {
    return {};
  }

  return {
    class: 'cursor-pointer',
    id: 'btn-task',
    ajaxtype: 'open',
    taskid: taskId,
  };
}

onMounted(() => {
  fetchSteps();
});
</script>
