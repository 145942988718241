<template>
  <v-responsive>
    <v-app :theme="theme" class="bg-red-gradient">
      <v-app-bar
        prominent
        class="d-print-none"
        :color="env !== 'production' ? 'red-accent-2' : null"
      >
        <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer" />

        <v-toolbar-title class="font-weight-bold">
          RSI {{ env !== 'production' ? ' - ' + env : '' }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          v-if="impersonated"
          href="?asUser=off"
          color="red"
          text="Impersonate off"
        />

        <NavAddMenu v-if="$gates.hasPermission('user.is_employee')" />

        <NavSearch class="mx-2" />

        <v-btn :icon="icon" @click="onClick" />

        <NavNotification />
      </v-app-bar>

      <AppNav
        v-model="drawer"
        :color="env !== 'production' ? 'red-accent-1' : null"
      />

      <v-main>
        <slot name="header" />
        <div class="m-2">
          <slot />
        </div>
      </v-main>
    </v-app>
  </v-responsive>
</template>

<script setup>
import AppNav from '@/components/layouts/AppNav.vue';
import NavAddMenu from '@/components/menus/NavAddMenu.vue';
import NavSearch from '@/components/menus/NavSearch.vue';
import NavNotification from '@/components/menus/NavNotification.vue';
import { ref, computed, inject, watch } from 'vue';

const localDrawer = localStorage.getItem('drawer');
const theme = ref(localStorage.getItem('theme') || 'light');
const drawer = ref(localDrawer == null || localDrawer === 'true');
const { impersonated, env } = inject('sharedData');

watch(theme, (value) => {
  localStorage.setItem('theme', value);
});

watch(drawer, (value) => {
  localStorage.setItem('drawer', value);
});

const icon = computed(() => {
  return theme.value === 'light' ? 'mdi-weather-sunny' : 'mdi-weather-night';
});

function onClick() {
  theme.value = theme.value === 'light' ? 'dark' : 'light';
}
</script>
