/**
 * @param {Function} callback
 * @param {number} wait
 * @param {boolean} immediate
 * @returns {Function}
 */
export function debounce(callback, wait, immediate = false) {
  /** @type {number} timeout */
  var timeout;

  return function () {
    var context = this;
    var args = arguments;

    clearTimeout(timeout);

    if (immediate && !timeout) {
      callback(args, context);
    }

    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) {
        callback(args, context);
      }
    }, wait);
  };
}

/**
 * @param {object} object
 * @param {string} path
 * @returns {any}
 */
export const getFromDotNotation = (object, path) => {
  if (!object) {
    return undefined;
  }

  return path.split('.').reduce((obj, index) => {
    if (!obj) {
      return undefined;
    }

    return obj[index];
  }, object);
};

/**
 * @param {string} color
 * @returns {string|null}
 */
export const getVuetifyColor = (color) => {
  switch (color) {
    case 'warning':
    case 'table-warning':
      return 'bg-amber-lighten-4';
    case 'danger':
    case 'table-danger':
      return 'bg-red-lighten-4';
    case 'success':
    case 'table-success':
      return 'bg-green-lighten-4';
    case 'info':
    case 'table-info':
      return 'bg-blue-lighten-4';
    case 'primary':
    case 'table-primary':
      return 'bg-blue-lighten-2';
    case 'secondary':
    case 'table-secondary':
      return 'bg-grey-lighten-4';
    default:
      return null;
  }
};
