<template>
  <v-card>
    <v-card-title class="text-center">Financial statement</v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6">
          <v-table>
            <thead>
              <tr>
                <td colspan="3">
                  <span class="font-weight-bold">PAYABLE AMOUNT</span>

                  <span v-if="reprimand.id" class="float-right d-print-none">
                    <v-btn
                      id="btn-reprimand-summary"
                      v-can="'reprimand.summary.create'"
                      v-tooltip="'Add payable'"
                      :reprimandid="reprimand.id"
                      ajaxtype="new"
                      icon="mdi-cash-plus"
                      variant="text"
                    />
                  </span>
                </td>
              </tr>
            </thead>
          </v-table>

          <ReprimandSummaryTable
            v-if="reprimand.id"
            :summaries="reprimand.summaries"
            :reprimand-id="reprimand.id"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-table>
            <thead>
              <tr>
                <td colspan="3">
                  <span class="font-weight-bold">CHARGES</span>

                  <span v-if="reprimand.id" class="float-right d-print-none">
                    <v-btn
                      id="btn-reprimand-cost"
                      v-can="'reprimand.summary.create'"
                      v-tooltip="'Add payable'"
                      :reprimandid="reprimand.id"
                      ajaxtype="new"
                      icon="mdi-cash-plus"
                      variant="text"
                    />
                  </span>
                </td>
              </tr>
            </thead>
          </v-table>

          <SectionCostTable
            v-if="reprimand.id"
            v-model:items-counter="itemsCounter"
            :section-id="reprimand.id"
            section="reprimand"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="justify-content-end font-weight-bold text-h5">
      Grand total: {{ formatMoney(reprimand.total_amount) }}
    </v-card-actions>
  </v-card>
</template>

<script setup>
import SectionCostTable from '@/components/tables/general/SectionCostTable.vue';
import ReprimandSummaryTable from '@/components/tables/reprimand/ReprimandSummaryTable.vue';

import { formatMoney } from '@/utils/formatter/number.js';
import { ref } from 'vue';

defineProps({
  reprimand: {
    type: Object,
    required: true,
  },
});

const itemsCounter = ref(0);
</script>
