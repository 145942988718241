<template>
  <v-container>
    <v-form>
      <v-row>
        <v-col v-for="(bulkList, key) in bulkLists" :key="key" cols="12">
          <div class="font-weight-bold">
            {{ formatTitle(key) }}
          </div>
          <v-row>
            <v-col v-for="template in bulkList" :key="template.id" cols="6">
              <v-checkbox
                :value="true"
                :model-value="subscriptions.includes(template.id)"
                :label="template.display_name"
                :disabled="loading.includes(template.id)"
                @click="toggleSubscription(template.id)"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script setup>
import {
  getUserSubscriptions,
  getBulkLists,
  bulkSubscribe,
  bulkUnsubscribe,
} from '@/services/api/notification.js';
import { ref } from 'vue';

const bulkLists = ref([]);
const subscriptions = ref([]);
const loading = ref([]);

/**
 * @param {number} id
 * @returns {Promise<void>}
 */
function toggleSubscription(id) {
  const index = subscriptions.value.indexOf(id);
  loading.value.push(id);

  if (index === -1) {
    return bulkSubscribe(id).then(() => {
      const index = subscriptions.value.indexOf(id);
      index === -1 && subscriptions.value.push(id);
      loading.value.splice(loading.value.indexOf(id), 1);
    });
  }

  return bulkUnsubscribe(id).then(() => {
    const index = subscriptions.value.indexOf(id);
    index !== -1 && subscriptions.value.splice(index, 1);
    loading.value.splice(loading.value.indexOf(id), 1);
  });
}

/**
 * @param {string} section
 * @returns {string}
 */
function formatTitle(section) {
  switch (section) {
    case 'debt':
      return 'Debt/Rental';
    case 'reprimand':
      return 'Formal written notice';
    case 'invoice':
      return 'Invoices';
    case 'repo':
      return 'Repossession';
    case 'storage':
      return 'Storage';
    case 'impound':
      return 'Impound';
    case 'vehicle':
      return 'Vehicle';
    default:
      return section;
  }
}

getBulkLists().then((lists) => {
  bulkLists.value = lists;
});

getUserSubscriptions().then((subs) => {
  subscriptions.value = subs;
});
</script>
