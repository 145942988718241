<template>
  <v-card>
    <v-card-title class="text-center">
      Invoices

      <span class="float-right d-print-none">
        <v-btn
          v-tooltip="createBtnProps['v-tooltip']"
          v-can="section + '.invoice.create'"
          icon="mdi-receipt-text-plus"
          variant="text"
          v-bind="createBtnProps"
        />
      </span>
    </v-card-title>

    <v-card-text>
      <SectionInvoiceTable
        v-model:items-counter="itemsCounter"
        :section-id="sectionId"
        :section="section"
      />
    </v-card-text>
  </v-card>
</template>

<script setup>
import SectionInvoiceTable from '@/components/tables/invoice/SectionInvoiceTable.vue';
import { computed } from 'vue';

const itemsCounter = defineModel('itemsCounter', {
  type: Number,
  default: 0,
});

const { section, sectionId } = defineProps({
  sectionId: {
    type: Number,
    required: true,
  },

  section: {
    type: String,
    required: true,
  },
});

// bridge for legacy code
const createBtnProps = computed(() => {
  switch (section) {
    case 'storage':
      return {
        'v-tooltip': 'Bill storage period',
        'data-storage-id': sectionId,
        'data-ajax-type': 'newS',
        'data-target': 'btn-invoice',
      };

    default:
      return {
        'v-tooltip': 'Create invoice',
        'data-reprimand-id': sectionId,
        'data-ajax-type': 'newR',
        'data-target': 'btn-invoice',
      };
  }
});
</script>
