<template>
  <v-table>
    <thead>
      <tr>
        <th>Description</th>
        <th class="text-right">Amount</th>
        <th
          v-can:any="'reprimand.summary.update|reprimand.summary.delete'"
          class="text-right d-print-none"
        >
          Options
        </th>
      </tr>
    </thead>

    <tbody>
      <tr>
        <td
          v-can:unless="'reprimand.summary.view'"
          colspan="3"
          class="text-center"
        >
          You don't have permissions to see the payables
        </td>
      </tr>
      <template v-if="$gates.hasPermission('reprimand.summary.view')">
        <tr v-for="summary in summaries" :key="summary.id">
          <td>
            <span>{{ summary.description }}</span>
            <v-icon
              v-if="summary.note"
              v-tooltip="summary.note"
              color="grey"
              class="ml-1"
              icon="mdi-message-text"
            />
          </td>

          <td class="text-right">
            {{ formatMoney(summary.amount) }}
          </td>

          <td
            v-can:any="'reprimand.summary.update|reprimand.summary.delete'"
            class="text-right d-print-none"
          >
            <v-menu>
              <template #activator="{ props: aProps }">
                <v-btn
                  icon="mdi-dots-vertical"
                  v-bind="aProps"
                  variant="text"
                />
              </template>

              <v-list>
                <v-list-item v-can="'reprimand.summary.update'">
                  <v-btn
                    id="btn-reprimand-summary"
                    :summaryid="summary.id"
                    ajaxtype="edit"
                    variant="text"
                    color="blue"
                    text="Edit"
                    block
                  />
                </v-list-item>

                <v-list-item v-can="'reprimand.summary.delete'">
                  <v-btn
                    color="red"
                    variant="text"
                    text="Delete"
                    block
                    @click="deleteCost(summary.id)"
                  />
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </tbody>

    <tfoot v-can="'reprimand.summary.view'">
      <tr class="bg-grey-lighten-1 font-weight-bold">
        <td>Subtotal</td>
        <td class="text-right">{{ formatMoney(totalAmount) }}</td>
        <td
          v-can:any="'reprimand.summary.update|reprimand.summary.delete'"
        ></td>
      </tr>
    </tfoot>
  </v-table>
</template>

<script setup>
import { formatMoney } from '@/utils/formatter/number.js';
import { computed } from 'vue';

const { summaries } = defineProps({
  summaries: {
    type: Array,
    required: false,
    default: () => [],
  },

  reprimandId: {
    type: Number,
    required: true,
  },
});

const totalAmount = computed(() => {
  return summaries.reduce((acc, summary) => acc + summary.amount, 0);
});
</script>
