<template>
  <v-card>
    <v-card-title class="text-center">
      Notes

      <span class="float-right d-print-none">
        <v-btn
          v-can="section + '.note.create'"
          v-tooltip="'Add note'"
          icon="mdi-message-plus"
          variant="text"
          v-bind="createBtnProps"
        />
      </span>
    </v-card-title>

    <v-card-text>
      <SectionNoteTable
        v-model:items-counter="itemsCounter"
        :section-id="sectionId"
        :section="section"
      />
    </v-card-text>
  </v-card>
</template>

<script setup>
import SectionNoteTable from '@/components/tables/general/SectionNoteTable.vue';

import { computed } from 'vue';

const itemsCounter = defineModel('itemsCounter', {
  type: Number,
  default: 0,
});

const { section, sectionId } = defineProps({
  sectionId: {
    type: Number,
    required: true,
  },

  section: {
    type: String,
    required: true,
  },
});

// bridge for legacy code
const createBtnProps = computed(() => {
  switch (section) {
    case 'storage':
      return {
        id: 'btn-note',
        'data-toggle': 'modal',
        'data-target': '#uppersite-menu',
        storageid: sectionId,
        ajaxtype: 'newS',
      };

    case 'reprimand':
      return {
        id: 'btn-note',
        'data-toggle': 'modal',
        'data-target': '#uppersite-menu',
        reprimandid: sectionId,
        ajaxtype: 'newR',
      };

    default:
      return {};
  }
});
</script>
