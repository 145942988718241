<template>
  <DeleteButton
    v-if="deleteUrl"
    v-model="deleteModal"
    :url="deleteUrl"
    no-button
    @deleted="fetchNotes"
  />

  <v-table>
    <thead>
      <tr>
        <th>Author</th>
        <th>Message</th>
        <th class="text-right">Date</th>
        <th v-can:any="optionsPermissions" class="text-right d-print-none">
          Options
        </th>
      </tr>
    </thead>

    <tbody>
      <tr>
        <td
          v-can:unless="section + '.note.view'"
          colspan="4"
          class="text-center"
        >
          You don't have permissions to see the notes
        </td>
      </tr>
      <template v-if="$gates.hasPermission(section + '.note.view')">
        <tr
          v-for="note in notes"
          :key="note.id"
          :class="{
            'bg-red-lighten-4': note.pop_up,
            'bg-blue-lighten-4': note.status_changed,
          }"
        >
          <td>
            <UserLink :user="note.createdBy" bold />
          </td>

          <td>
            <span>{{ note.message }}</span>
          </td>

          <td class="text-right">
            {{ formatDate(note.date, 'shortDateTime') }}
          </td>

          <td v-can:any="optionsPermissions" class="text-right d-print-none">
            <v-menu>
              <template #activator="{ props: aProps }">
                <v-btn
                  icon="mdi-dots-vertical"
                  v-bind="aProps"
                  variant="text"
                />
              </template>

              <v-list>
                <v-list-item v-can="section + '.note.update'">
                  <v-btn
                    id="btn-note"
                    data-toggle="modal"
                    data-target="#uppersite-menu"
                    ajaxtype="edit"
                    :noteid="note.id"
                    variant="text"
                    color="blue"
                    text="Edit"
                    block
                  />
                </v-list-item>

                <v-list-item v-can="section + '.note.delete'">
                  <v-btn
                    color="red"
                    variant="text"
                    text="Delete"
                    block
                    @click="deleteNote(note.id)"
                  />
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </tbody>
  </v-table>
</template>

<script setup>
import DeleteButton from '@/components/general/DeleteButton.vue';
import UserLink from '@/components/links/UserLink.vue';

import { computed, inject, ref, watch } from 'vue';
import { format as formatDate } from '@/utils/formatter/date.js';
import axios from '@/plugins/axios.js';

const $gates = inject('$gates');

const itemsCounter = defineModel('itemsCounter', {
  type: Number,
  default: 0,
});

const { section, sectionId } = defineProps({
  sectionId: {
    type: Number,
    required: true,
  },

  section: {
    type: String,
    required: true,
  },
});

const notes = ref([]);
const selectedNote = ref(null);
const deleteModal = ref(false);

watch(() => sectionId, fetchNotes);

const optionsPermissions = computed(() => {
  return section + '.note.update|' + section + '.note.delete';
});

const deleteUrl = computed(() => {
  if (!selectedNote.value || !sectionId) {
    return null;
  }

  return route('api.v1.' + section + 's.notes.destroy', [
    sectionId,
    selectedNote.value?.id,
  ]);
});

function fetchNotes() {
  if (!$gates.hasPermission(section + '.note.view')) {
    return;
  }

  if (!sectionId) {
    setTimeout(() => {
      if (!sectionId) {
        console.error('Section ID is not set');
      }
    }, 1000);

    return;
  }

  axios
    .get(route('api.v1.' + section + 's.notes.index', [sectionId]))
    .then(({ data }) => {
      notes.value = data.data;
      itemsCounter.value = notes.value.length;
    });
}

/**
 * @param {number} noteId
 */
function deleteNote(noteId) {
  selectedNote.value = notes.value.find((note) => note.id === noteId);

  if (selectedNote.value) {
    deleteModal.value = true;
  }
}

fetchNotes();
</script>
